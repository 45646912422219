'use client';
import config from '@haaretz/l-config';
import useArticleId from '@haaretz/s-atoms/articleId';
import useCookie from '@haaretz/s-atoms/cookie';
import usePageStatusToken from '@haaretz/s-atoms/pageStatusToken';
import useUser from '@haaretz/s-atoms/user';
import useLogout from '@haaretz/s-auth-hooks/use-logout';
import useAccessListByIpMutation from '@haaretz/s-client-data-hooks/useAccessListByIpMutation';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import getBaseHostname from '@haaretz/s-common-utils/getBaseHostname';
import { site } from '@haaretz/s-consts';
import NoSSR from '@haaretz/s-no-ssr';
import checkProxyHostname from '@haaretz/s-url-utils/checkProxyHostname';
import isArticleURL from '@haaretz/s-url-utils/isArticleUrl';
import { usePathname } from 'next/navigation';
import React from 'react';

import type { LogoutMutationVariables } from '@haaretz/s-lgn-mutations/Logout';

const userStatsUrl = config.get('userStats');

const sendedRequests = new Set<string>();

/**
 * Update the user cookie *in the background* on every navigation
 */
function PlantUserCookie() {
  const pathname = usePathname();
  const user = useUser('cookieValue');
  const articleId = useArticleId();
  const pageStatusToken = usePageStatusToken();
  const cookie = useCookie('serverValue');

  const logout = useLogout({
    onError: error => {
      console.error(error);
    },
  });

  const isUniversityUser = user.university === true;

  const { mutate: checkIp, data } = useAccessListByIpMutation();

  React.useEffect(() => {
    if (isUniversityUser && cookie.acl) {
      checkIp();
    }
  }, [checkIp, cookie.acl, isUniversityUser]);

  React.useEffect(() => {
    if (data === false && !logout.isLoading && !logout.isSuccess) {
      const isProxyServer = checkProxyHostname(window.location.hostname);

      const variables: LogoutMutationVariables = isProxyServer
        ? {
            input: {
              proxyDomain: `.${encodeURIComponent(getBaseHostname(window.location.hostname))}`,
            },
          }
        : {};

      logout.mutate(variables);
    }
  }, [data, logout]);

  const isValidParamsAccordingToPathname = isArticleURL(pathname)
    ? articleId && pageStatusToken
    : !articleId && !pageStatusToken;

  React.useEffect(() => {
    if (
      userStatsUrl &&
      user.userType !== 'anonymous' &&
      isValidParamsAccordingToPathname &&
      !(pageStatusToken && sendedRequests.has(pageStatusToken)) &&
      (!isUniversityUser || data === true)
    ) {
      const timeoutId = setTimeout(() => {
        // Using `startTransition` here to mark the planting of the cookie as a low-priority uodate
        React.startTransition(() => {
          const searchParams = new URLSearchParams(
            discardNullableValues({
              site,
              isUniversity: String(user.university === true),
              articleId,
            })
          );

          const searchParamsString = searchParams.toString();

          const headers: HeadersInit = discardNullableValues({
            token: pageStatusToken,
          });

          if (pageStatusToken) sendedRequests.add(pageStatusToken);

          fetch(`${userStatsUrl}/getUserData?${searchParamsString}`, {
            credentials: 'include',
            headers,
            priority: 'low',
          }).catch((error: Error) => console.error(`[setUserCookie] error: ${error.message}`));

          if (user.university !== true && user.userType === 'paying') {
            fetch(`${userStatsUrl}/getProductsCookie?${searchParamsString}`, {
              credentials: 'include',
              priority: 'low',
            }).catch((error: Error) =>
              console.error(`[setProductsCookie] error %o: ${error.message}`)
            );
          }
        });
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    return undefined;
  }, [
    articleId,
    data,
    isUniversityUser,
    isValidParamsAccordingToPathname,
    pageStatusToken,
    user.university,
    user.userType,
  ]);

  return null;
}

export default function NoSSRWrapper() {
  return (
    <NoSSR>
      <PlantUserCookie />
    </NoSSR>
  );
}
