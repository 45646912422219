'use client';

import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
import Icon from '@haaretz/s-icon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import s9 from 'style9';

import getAbsolutePosition from './positioning/getAbsolutePosition';
import getRelativePosition from './positioning/getRelativePosition';

import type { OnboardingAbsolutePosition } from './positioning/getAbsolutePosition';
import type { OnboardingRelativePosition } from './positioning/getRelativePosition';
import type { ReactNode } from 'react';

interface OboardingElementClientProps {
  portalId?: string | null;
  referenceId: string;
  children: ReactNode;
}

type OnboardingPosition = OnboardingRelativePosition | OnboardingAbsolutePosition;

const c = s9.create({
  base: {
    display: 'inline-flex',
    gap: space(1),
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: space(1),
  },
  baseAbsolute: {
    position: 'absolute',
    zIndex: zIndex('above'),
  },
  reverse: {
    flexDirection: 'row-reverse',
  },
  arrowBox: {
    flexShrink: 0,
    transform: `translateY(-${space(1)})`,
    alignSelf: 'baseline',
    aspectRatio: '5 / 6',
    width: space(6),
    ...merge(
      mq({
        from: 's',
        value: {
          width: space(9),
        },
      })
    ),
  },
  arrow: {
    width: '100%',
    height: '100%',
    transform: fork({
      default: `translateY(-${space(1)}) translateX(-30%) rotateY(0)`,
      hdc: `translateY(-${space(1)}) translateX(30%) rotateY(180deg)`,
    }),
  },
  arrowReverse: {
    transform: fork({
      default: 'translateX(30%) rotateY(180deg)',
      hdc: 'translateX(-30%) rotateY(0)',
    }),
  },
});

export default function OnboardingElementClient({
  referenceId,
  portalId,
  children,
}: OboardingElementClientProps) {
  const [portal, setPortal] = useState<Element | null>(null);
  const [reference, setReference] = useState<Element | null>(null);
  const [position, setPosition] = useState<OnboardingPosition>();

  const getPosition = useMemo(
    () => (portalId ? getRelativePosition : getAbsolutePosition),
    [portalId]
  );

  useEffect(() => {
    const portalDom = portalId && document.getElementById(portalId);
    const referenceDom = document.getElementById(referenceId);

    if (portalDom) {
      setPortal(portalDom);
    }

    if (referenceDom) {
      setReference(referenceDom);
    }
  }, [portalId, referenceId]);

  const updatePosition = useCallback(
    (element: HTMLDivElement) => {
      if (element && reference) {
        const pos = getPosition(reference, element);
        setPosition(pos);
      }
    },
    [getPosition, reference]
  );

  // Any oboarding-element must be attached to a DOM element
  if (!reference) {
    return null;
  }

  const transforms = [];
  if (position?.x) {
    transforms.push(`translateX(${position.x}px)`);
  }

  if (position?.y) {
    transforms.push(`translateY(${position.y}px)`);
  }

  const jsx = (
    <div
      ref={updatePosition}
      data-testid="onboarding-position"
      className={s9(c.base, !portalId && c.baseAbsolute, position?.side === 'start' && c.reverse)}
      style={
        transforms.length
          ? {
              transform: transforms.join(' '),
              maxWidth: `${position?.maxWidth}px`,
            }
          : {}
      }
    >
      {children}
      <div className={s9(c.arrowBox)}>
        <Icon
          icon="arrow-curved"
          styleExtend={[c.arrow, position?.side === 'start' && c.arrowReverse]}
        />
      </div>
    </div>
  );

  if (portalId) {
    // if portal-id is provided than onboarding-element must appended to the portal
    return portal ? createPortal(jsx, portal) : null;
  }

  // if portal is not required for this onboarding element
  return jsx;
}
